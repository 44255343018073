.login-wrap {
    background: url(./images/bg.jpg) no-repeat center/cover;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    &-card {
        width: 500px;
        box-shadow: 0px 5px 10px rgba($color: #000000, $alpha: 0.5);
        border-radius: 5px;
    }
}
