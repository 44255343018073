#root {
    height: 100%;
}
.trigger {
    padding: 0 24px;
    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;
}

.trigger:hover {
    color: #1890ff;
}

.logo {
    height: 32px;
    margin: 16px;
    background: rgba(255, 255, 255, 0.3);
    color: #fff;
    line-height: 32px;
    text-align: center;
    font-weight: bold;
}

.site-layout .site-layout-background {
    background: #fff;
}

.app-layout {
    height: 100%;
}
.mt-10 {
    margin-top: 10px;
}
